<template>
    <v-container fluid >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="targetForm" v-model="valid">
            <v-row>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.default_target"
                        :counter="5"
                        :rules="[rules.required,rules.unum, rules.noSpace]"
                        :label="$store.getters.getTextMap().name"
                        outlined
                        dense
                        required
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        :dark="$store.getters.getColorPalette().isDark"
                        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        :loading="loading"
                        v-model="form.msg"
                        :counter="45"
                        outlined
                        dense
                        :label="$store.getters.getTextMap().description_optional"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="d-flex" align="right" align-self="center">
                    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                    <v-btn small class="mx-1" v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">{{$store.getters.getTextMap().submit}}</v-btn>
                    <v-btn small v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark"  @click="close">{{$store.getters.getTextMap().cancel}}</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import FormRules from "@/utillities/FormRules"
import axios from 'axios'
export default {
    name:'CreateTarget',
    props:['target'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.target && this.target.target_id){
            this.api='updateTarget'
            this.editMode=true
            this.form=Object.assign({},this.target) 
        }else{
            this.api='createTarget'
            this.editMode=false
        }
    },
    data(){
        return {
            api:'createTarget',
            editMode:false,
            valid:false,
            loading:false,
            info:'',
            showDismissibleAlert:false,
            form:{
                name:null,
                msg:null,
                meta:null
            },
            rules:FormRules.rules,
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
        }
    },
    methods:{
        close(){
            this.$emit("close")
        },
        onSubmit(){
            this.$refs.targetForm.validate()
            if(this.valid){
                this.loading=true
                let d=Object.assign({}, this.form)
                if(this.editMode){
                    d['target_id']=this.target.target_id
                }
                axios.post(this.$store.state.api+this.api,d,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response=>{
                    if(response.data.status=='success'){
                        this.$store.dispatch('refreshTargets')
                        this.loading=false
                        this.$emit('update')
                        if(!this.editMode){
                            this.$refs.targetForm.reset()
                        }
                        this.close()
                    }else{
                        this.loading=false
                        this.info=response.data.msg
                        this.showDismissibleAlert=true
                    }
                }).catch(err=>{
                    this.loading=false
                    this.info=err
                    this.showDismissibleAlert=true
                })
            }
        }
    }
}
</script>